export var HOME = '/';
export var APPLICATION = '/applications/:applicationId';
export var REGISTRY = '/registry';
export var ADMIN = '/admin';
export var REGISTER_TOKEN = '/applicants/register';
export var VERIFY = '/verifiers/verify';
export var WALLET = '/wallet';
export var FAQ = '/faq';
export var CHALLENGES = '/challenges';
export var CHALLENGES_PAGE = '/challenges/page/:currentPage';
export var LISTING = '/listings/:listingHash';